html {
  font-size: 62.5%;
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-table,
.MuiTypography-body1,
.MuiFormLabel-root,
.MuiTab-root {
  font-family: 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.16);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #707070;
}

code {
  font-family: 'Helvetica Neue', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

#root {
  height: 100vh;
  min-height: 780px;
}

.MuiInputBase-root .MuiInputBase-input {
  font-size: 1.2rem;
  font-family: 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif !important;
}

.MuiFormControl-root label {
  color: #838383;
  font-size: 1.3rem;
  font-weight: bold;
  position: unset;
  transform: unset;
  margin-top: 20px;
}

.MuiInputBase-multiline {
  border-radius: 4px;
  border: solid 0.5px #b4b4b4;
  background-color: #fff;
  padding: 8px !important;
  min-height: 36px;
}

.MuiFormControl-root label + .MuiInput-formControl {
  margin-top: 8px;
}

.MuiInputBase-root .MuiSelect-selectMenu,
.MuiFormControl-root input {
  border-radius: 4px;
  border: solid 0.5px #b4b4b4;
  background-color: #fff;
  padding-left: 8px;
}

.MuiSvgIcon-root.MuiSelect-icon {
  top: unset;
}

.MuiFormControl-root .MuiInput-underline:after,
.MuiFormControl-root .MuiInput-underline:before {
  display: none;
}

.MuiButtonBase-root .MuiIconButton-label {
  color: #707070;
}

.MuiTabs-root {
  min-height: 38px !important;
}

.MuiTab-root.MuiTab-labelIcon {
  min-height: unset;
}

.section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
}

.section-title {
  font-family: HelveticaNeue;
  font-size: 1.6rem;
  font-weight: bold;
  color: #262626;
}

.section-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
  color: #262626;
}

.my-requests,
.recent-documents {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.entity-list .MuiIconButton-root {
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.f1 {
  flex: 1;
}

.search-bar .MuiOutlinedInput-root {
  border-color: #b4b4b4;
}

.search-bar .MuiInputAdornment-root svg {
  height: 22px;
  width: 22px;
}

.search-bar input {
  padding: 8px 14px 8px 0;
  border: unset;
}

.button-create {
  background-color: #0909B7!important;
  margin-right: 8px;
  padding: 4px 8px;
}

.button-create:hover {
  background-color: #001A5B !important;
  /* opacity: 0.8 !important; */
}

.button-create .MuiButton-label {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  min-height: 18px;
}

.button-create .MuiButton-endIcon {
  margin-left: 4px;
}

.MuiCheckbox-root .MuiIconButton-label {
  color: #707070;
}

.rv-treemap__leaf {
  background: white !important;
  display: flex;
  border: 1px solid #000;
  justify-content: center;
  align-items: center;
}

.MuiFormHelperText-root.Mui-error {
  font-size: 11px;
}

.MuiButtonBase-root.MuiButtonBase-root.MuiTabScrollButton-root {
  width: 24px;
}

.MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.8;
}

.MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled svg {
  opacity: 0;
}

.MuiPickersDay-dayDisabled .MuiIconButton-label {
  color: lightgray;
}

.grecaptcha-badge {
  display: none !important;
}
